import React, { useState, useEffect } from 'react';
import { useParams, Navigate, Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Container from 'react-bootstrap/Container';
import AuthService from './AuthService';
import './Players.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function PlayersList(props) {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      fetch(`/players`, {
        method: 'GET',
        headers: {
          Authorization: bearerToken
        }
      })
      .then(resp => resp.json())
      .then(data => setPlayers(data))
    }
  }, []);

  function handleAdd() {
    const bearerToken = new AuthService().getToken();
    if(bearerToken != null) {
      fetch(`/players`, {
        method: 'POST',
        headers: {
          Authorization: bearerToken
        }
      })
      .then(resp => resp.json())
      .then(data => setPlayers(data))
    }
  }

  function handleDelete(pid) {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const bearerToken = new AuthService().getToken();
            if(bearerToken != null) {
              fetch(`/players/`+pid, {
                method: 'DELETE',
                headers: {
                  Authorization: bearerToken
                }
              })
              .then(resp => resp.json())
              .then(data => setPlayers(data))
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  if((new AuthService()).loggedIn()) {
    return <>
    <Container>
    <h3>All Players</h3>
    <Table striped bordered>
      <thead>
        <tr><th colSpan="2">{players.length} player(s)</th><th><Link to='/games'>Games</Link>&nbsp;&nbsp;<button onClick={() => { handleAdd() }}>Add Player</button>&nbsp;&nbsp;<Link to='/logout'>logout</Link></th></tr>
        <tr><th>Id</th><th>Name</th></tr>
      </thead>
      <tbody>
      {
        players.map((player) => {
          return (
            <tr key={player.id}>
              <td>{player.id}</td>
              <td>
                <Link to={`/players/${player.playerid}`}>
                  {player.name}
                </Link>
              </td>
              <td>
                <button onClick={() => { handleDelete(player.id) }}>Delete</button>
              </td>
            </tr>
          )
        })
      }
      </tbody>
    </Table>
    </Container>
  </>
  } else {
    return <Navigate to='/login' />
  }
}
